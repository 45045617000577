<template>
  <div class="addbanner-continer">
    <div class="addbanner-center">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="模块名称" prop="name">
          <el-input
            placeholder="请输入模块名称"
            v-model="ruleForm.name"
          ></el-input>
        </el-form-item>
        <el-form-item label="logo上传" prop="name">
          <el-upload
            :class="listLength==1?'hide_box':''"
            action=""
            :on-change="handleelchange"
            :on-remove="handleRemove"
            :auto-upload="false"
            list-type="picture-card"
            :limit="1"
            accept=".png,.jpg"
            :file-list="fileList"
          >
            <div class="el-upload  file-upload-container">
                 <i class="el-icon-plus"></i>
                  <span class="a">点击上传</span>
          </div>
              <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过50kb</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="模块提示语">
          <el-input
            type="textarea"
            maxlength="16"
            show-word-limit
            v-model="ruleForm.text"
          ></el-input>
        </el-form-item>
        <el-form-item label="模块开关">
          <el-checkbox
            :true-label="true"
            :false-label="false"
            v-model="checked"
          ></el-checkbox>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit()">保存</el-button>
          <el-button @click="cancel()">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>

import {AddOrEdit,getDetail} from '../../api/moudels'
  export default {
 data() {
      return {
        img2:"",
        listLength:0,
            imgsrc:"",
        ruleForm: {
          name: '',
          region: '',
          date1: '',
          date2: '',
          lz:'',
          text:''
        },
        fileList:'',
        checked:true,
        filell:{},
        id:'',
        rules: {
          name: [
            { required: true, message: '请输入模块名称', trigger: 'blur' },
            { min: 1, max: 16, message: '长度在 1 到 16 个字符', trigger: 'blur' }
          ]
        }
      };
    },
  watch:{
      // fileList(news,olds){
      //     if(news.length>0){
      //       this.imageUrl=""
      //     }
      // }
  },
  methods: {
      handleRemove(file, fileList) {
        console.log(file, fileList);
        // 删除图片把当前图片列表的length赋值给listLength
		this.listLength = fileList.length
      },
      uploadDisabled() {
      return this.goodsimage.length > 0   //判断图片上传的数量动态控制按钮隐藏与显示
    },

    handleelchange(file, fileList) {
      console.log(file,fileList);
            this.fileList=fileList
          this.filell = file.raw;
      this.listLength=fileList.length
    },

    //新增
    onSubmit() {
      if (this.num == 0) {
        const formData = new FormData();
        formData.append("enable", this.checked);
        formData.append("uploadedFile", this.filell);
        formData.append("tip", this.ruleForm.text);
        formData.append("name", this.ruleForm.name);
        AddOrEdit(formData).then((res) => {
          console.log(res);
          if (res.code === 10000) {
            this.$message("新增成功");
            this.$router.push({ path: "/moudels" });
          } else {
            this.$message(res.msg);
          }
        });
      } else if (this.num == 1) {
        const formData = new FormData();
        formData.append("enable", this.checked);
        formData.append("uploadedFile", this.filell);
        formData.append("tip", this.ruleForm.text);
        formData.append("name", this.ruleForm.name);
        formData.append("moduleManageId", this.id);
        AddOrEdit(formData).then((res) => {
          console.log(res);
          if (res.code === 10000) {
            this.$message("编辑成功");
            this.$router.push({ path: "/moudels" });
          } else if (res.code === -1) {
               this.$message(res.msg);
          }
        });
      }
    },
    //取消
    cancel() {
      this.$router.push({ path: "/moudels" });
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.num = this.$route.query.num;
    console.log(this.num);
    if (this.num == 1) {
      let data = {
        id: this.id,
      };
      getDetail(data).then((res) => {
        console.log(res);
        let d=[]
        d.push({url:res.data.logoFileUrl})
        this.fileList=d
        console.log(this.fileList);
        this.listLength=this.fileList.length
        console.log(this.listLength);
        // this.img2= res.data.logoFile;
        (this.ruleForm.name = res.data.name),
          (this.ruleForm.text = res.data.tip);
        this.checked = res.data.enable;
        // this.img2 = res.data.logoFileUrl;
      });
    }
  },
};
</script>

<style  scoped>
.addbanner-continer {
  width: 100%;
  height: 100%;
  background: white;
}
.addbanner-center {
  width: 430px;
  height: 500px;
  margin-left: 5px;
}
.avatar{
  width: 150px;
  height: 150px;
}
.imgoption{
  width: 100px;
  height: 100px;
  border: 1px solid #ccc; 
  background: 	LightSlateGray;


}
.el-upload{
  position: relative;
}
.a{
  width: 72px;
  position: absolute;
  left: 50%;
  top: 20px;
  margin-left:-36px;
}
.imgi{
  margin-left: 41px;
  margin-top: 30px;
}
.wh148{
width:148px;
height:148px;
}
.obfc{
  object-fit: contain;
}
/deep/.el-upload--picture-card{
  background: #C0C0C0;
}
.hide_box >>> .el-upload--picture-card{
  display: none;
}
</style>